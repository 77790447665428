// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
h1, h3, h2 span {
  font-family: 'Segoe', sans-serif; /* Example font-family */
  letter-spacing: -0.1rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-size: 2.3rem;
  display: inline;

  }
  @keyframes devAnimation {
    0% {
      opacity: 0;
      transform: translateX(-20%);
    }
    50% {
      opacity: 1;
      transform: translateX(0%);
    }
    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }
  
  .dev-text {
    font-family: 'Courier', sans-serif; /* Example font-family */
    animation: devAnimation 5s infinite; /* Adjust the duration and other properties as needed */
  }


.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    border-radius: 3px;
  }
}

.avatar-holder{
  align-items: center;
}

@keyframes rotate-color {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.animate-gradient {
  animation: rotate-color 5s linear infinite;
  -webkit-animation: rotate-color 5s linear infinite;
}`, "",{"version":3,"sources":["webpack://./src/views/index/style.css"],"names":[],"mappings":";AACA;EACE,gCAAgC,EAAE,wBAAwB;EAC1D,uBAAuB;EACvB,oBAAoB;EACpB,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;;EAEf;EACA;IACE;MACE,UAAU;MACV,2BAA2B;IAC7B;IACA;MACE,UAAU;MACV,yBAAyB;IAC3B;IACA;MACE,UAAU;MACV,2BAA2B;IAC7B;EACF;;EAEA;IACE,kCAAkC,EAAE,wBAAwB;IAC5D,mCAAmC,EAAE,uDAAuD;EAC9F;;;AAGF;EACE,sCAAsC;AACxC;;AAEA;EACE;IACE,wCAAwC;IACxC,kBAAkB;EACpB;EACA;IACE,uCAAuC;IACvC,kBAAkB;EACpB;AACF;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;AASA;EACE,0CAA0C;EAC1C,kDAAkD;AACpD","sourcesContent":["\nh1, h3, h2 span {\n  font-family: 'Segoe', sans-serif; /* Example font-family */\n  letter-spacing: -0.1rem;\n  line-height: 2.25rem;\n  font-weight: 600;\n  font-size: 2.3rem;\n  display: inline;\n\n  }\n  @keyframes devAnimation {\n    0% {\n      opacity: 0;\n      transform: translateX(-20%);\n    }\n    50% {\n      opacity: 1;\n      transform: translateX(0%);\n    }\n    100% {\n      opacity: 0;\n      transform: translateX(100%);\n    }\n  }\n  \n  .dev-text {\n    font-family: 'Courier', sans-serif; /* Example font-family */\n    animation: devAnimation 5s infinite; /* Adjust the duration and other properties as needed */\n  }\n\n\n.pulse {\n  animation: pulse-animation 2s infinite;\n}\n\n@keyframes pulse-animation {\n  0% {\n    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);\n    border-radius: 3px;\n  }\n  100% {\n    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);\n    border-radius: 3px;\n  }\n}\n\n.avatar-holder{\n  align-items: center;\n}\n\n@keyframes rotate-color {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes rotate-color {\n  from {\n    -webkit-transform: rotate(0deg);\n  }\n  to {\n    -webkit-transform: rotate(360deg);\n  }\n}\n.animate-gradient {\n  animation: rotate-color 5s linear infinite;\n  -webkit-animation: rotate-color 5s linear infinite;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
