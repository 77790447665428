// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeInLetters {
    0% { opacity: 0; }
    25% { opacity: 0.25; }
    50% { opacity: 0.5; }
    75% { opacity: 0.75; }
    100% { opacity: 1; }
  }
  
  .animate-dev {
    animation: fadeInLetters 2s forwards;
  }

  input:checked ~ span:last-child {
    --tw-translate-x: 1.75rem; /* translate-x-7 */
  }

li{
  width: 100%;
    display: flex;
    justify-content: center;
}
.liNavbar {
  width: 100%;
    display: flex;
    justify-content: center;
}
.carousel-container {
  display: flex;
  width: 100%;
  height: auto;
}

.carousel-image {
  width: 100%;
  height: auto;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.carousel-image.active {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/container/style.css"],"names":[],"mappings":"AAAA;IACI,KAAK,UAAU,EAAE;IACjB,MAAM,aAAa,EAAE;IACrB,MAAM,YAAY,EAAE;IACpB,MAAM,aAAa,EAAE;IACrB,OAAO,UAAU,EAAE;EACrB;;EAEA;IACE,oCAAoC;EACtC;;EAEA;IACE,yBAAyB,EAAE,kBAAkB;EAC/C;;AAEF;EACE,WAAW;IACT,aAAa;IACb,uBAAuB;AAC3B;AACA;EACE,WAAW;IACT,aAAa;IACb,uBAAuB;AAC3B;AACA;EACE,aAAa;EACb,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,UAAU;EACV,oCAAoC;AACtC;;AAEA;EACE,UAAU;AACZ","sourcesContent":["@keyframes fadeInLetters {\n    0% { opacity: 0; }\n    25% { opacity: 0.25; }\n    50% { opacity: 0.5; }\n    75% { opacity: 0.75; }\n    100% { opacity: 1; }\n  }\n  \n  .animate-dev {\n    animation: fadeInLetters 2s forwards;\n  }\n\n  input:checked ~ span:last-child {\n    --tw-translate-x: 1.75rem; /* translate-x-7 */\n  }\n\nli{\n  width: 100%;\n    display: flex;\n    justify-content: center;\n}\n.liNavbar {\n  width: 100%;\n    display: flex;\n    justify-content: center;\n}\n.carousel-container {\n  display: flex;\n  width: 100%;\n  height: auto;\n}\n\n.carousel-image {\n  width: 100%;\n  height: auto;\n  opacity: 0;\n  transition: opacity 0.5s ease-in-out;\n}\n\n.carousel-image.active {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
