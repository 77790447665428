// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `hr {
    margin-top: 1rem;
    margin-bottom: 1rem;

}
ul {
    /* Reset margin to 0 */
    margin: 0;
    /* Optionally reset padding to 0 if needed */
    padding: 0;
  }
`, "",{"version":3,"sources":["webpack://./src/views/projects/checkey/checkey.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;;AAEvB;AACA;IACI,sBAAsB;IACtB,SAAS;IACT,4CAA4C;IAC5C,UAAU;EACZ","sourcesContent":["hr {\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n\n}\nul {\n    /* Reset margin to 0 */\n    margin: 0;\n    /* Optionally reset padding to 0 if needed */\n    padding: 0;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
