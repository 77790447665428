// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-image {
  width: auto;
  max-height: 100%; /* Ensure images maintain aspect ratio and fit within container height */
}
.container {
  width: 100%; /* Ensure the carousel container takes full width */
}`, "",{"version":3,"sources":["webpack://./src/views/projects/parque-invierno/ParqueInvierno.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB,EAAE,wEAAwE;AAC5F;AACA;EACE,WAAW,EAAE,mDAAmD;AAClE","sourcesContent":[".carousel-image {\n  width: auto;\n  max-height: 100%; /* Ensure images maintain aspect ratio and fit within container height */\n}\n.container {\n  width: 100%; /* Ensure the carousel container takes full width */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
