// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-wrapper{
    min-height: 100vh;
    display: flex;
    justify-content: center;
}
.divider {
    
    width: '70vw'; 
    height: '2px';  
    margin: '0 auto'
  }

  
`, "",{"version":3,"sources":["webpack://./src/components/layout/style.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,uBAAuB;AAC3B;AACA;;IAEI,aAAa;IACb,aAAa;IACb;EACF","sourcesContent":[".content-wrapper{\n    min-height: 100vh;\n    display: flex;\n    justify-content: center;\n}\n.divider {\n    \n    width: '70vw'; \n    height: '2px';  \n    margin: '0 auto'\n  }\n\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
